import React from "react";
import {Button, Space} from "antd";
import {LeadModel} from "@/typings/models/Lead";
import get from "lodash/get";
import {MailOutlined, PhoneOutlined} from "@ant-design/icons";

import LeadName from "@/components/Lead/LeadName";
import {LeadCardOrderedProperties} from "@/components/Lead/LeadProperties";
import {LEAD_DETAILS_VISIBLE_PROPERTIES} from "@/components/Kanban/VisibilitySettings/constants";
import Card from "@/components/Card";
import {F} from "@/utils/intl";
import styles from "./index.less"
import classNames from "classnames";

export default function LeadDetailsHeader({lead}: {lead: LeadModel}){
  const [isDetailsExpanded, setIsDetailsExpanded] = React.useState(false)

  return <Card className={styles.leadDetailsHeaderCard}>
    <div className={styles.leadDetailsHeaderContainer}>
      <div className={styles.leadHeaderTop}>
        <div>
          <LeadName nameClassName={styles.leadHeaderName} avatarClassName={styles.leadHeaderAvatar} lead={lead}/>
        </div>
        <div className={styles.leadHeaderActions}>
          <Space>
            {get(lead, 'customer.email') &&
              <a href={`mailto:${get(lead, 'customer.email')}`}><Button className={'button-secondary button-bordered'}
                                                                        icon={<MailOutlined/>}>
                <F id={"pages.lead.action.email"} defaultMessage={'Email'}/>

              </Button></a>}
            {get(lead, 'customer.phone') &&
              <a href={`tel:${get(lead, 'customer.phone')}`}><Button className={'button-secondary button-bordered'}
                                                                     icon={<PhoneOutlined/>}>
                <F id={"pages.lead.action.call"} defaultMessage={'Call'}/>
              </Button>

              </a>}
          </Space>

          {/*<LeadContextMenu leadId={lead?.id} actionsFilter={['archive']}*/}
          {/*                 archiveContent={<Button size={"large"} icon={<InboxOutlined/>}/>}/>*/}
        </div>
      </div>
      <div className={styles.leadProperties}>
        {isDetailsExpanded && <LeadCardOrderedProperties
          grid={{
            sm: 8,
            xs: 12
          }}
          overrideVisibleProperties={LEAD_DETAILS_VISIBLE_PROPERTIES}
          lead={lead}
          showFieldName
          isTable
          spaceSize={24}/>}

        <Button className={classNames('color-secondary', styles.leadDetailsExpandButton)} onClick={() => setIsDetailsExpanded(prev => !prev)} type={"link"}> <F id={`pages.lead.drawer.view${isDetailsExpanded ? 'Less' : 'More'}`} defaultMessage={`View ${isDetailsExpanded ? 'Less' : 'More'}`}/></Button>
      </div>
    </div>
  </Card>
}

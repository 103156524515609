import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'umi';
import {Badge, Form, Space, Tabs, Tooltip} from 'antd';
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import Drawer from '@/components/Drawer';
import * as LeadSelectors from "@/selectors/leadSelectors";
import LeadName from "@/components/Lead/LeadName";
import LeadActivity from "@/components/Lead/LeadActivity";
import {F} from "@/utils/intl";
import LeadComments from "@/components/Lead/LeadComments";
import LeadDetailsServiceRequests from "@/components/Lead/LeadDetailsServiceRequests";

import styles from './index.less';
import {TasksList} from "@/pages/Tasks/TasksList";
import LeadDetailsHeader from "@/components/Lead/LeadDetailsDrawer/LeadDetailsHeader";
import classNames from "classnames";
import {OrderedListOutlined} from "@ant-design/icons";
import {GoTasklist} from "react-icons/go";
import {PiNotebookThin} from "react-icons/pi";
import {LiaCommentsSolid} from "react-icons/lia";
import LeadSource from "@/components/Lead/LeadSource";
import LeadUpdatedAt from "@/components/Lead/LeadUpdatedAt";

const LeadDetailsDrawer = () => {
  const [form] = Form.useForm()
  const detailsDrawer = useSelector(LeadSelectors.selectLeadDetailsDrawer)
  const leadId = detailsDrawer?.leadId
  const leadDetails = useSelector((state: DefaultRootState) => LeadSelectors.selectLeadDetails(state, leadId))
  const lead = useSelector((state: DefaultRootState) => LeadSelectors.selectLeadData(state, leadId))

  const commentsCount = get(leadDetails, 'activities', []).filter(activity => activity?.activity_type === 'comment').length
  const fetchLeadData = () => dispatch({
    type: 'leads/fetchSingleLead',
    payload: {
      leadId
    }
  })

  const tabs = [
    {
      key: 'activities',
      label: <Space>
        <OrderedListOutlined />
        <F id={"pages.lead.drawer.activities"} defaultMessage={'Activities'}/>
      </Space>,
      children: <div>
        <LeadActivity leadDetails={leadDetails}/>
      </div>,
    },
    {
      key: 'serviceRequests',
      label: <Space>
        <PiNotebookThin fontSize={0} size={20}/>
        <F id={"pages.requests.tableTitle"} defaultMessage={'Requests'}/>
      </Space>,
      children: <div>
        <LeadDetailsServiceRequests leadDetails={leadDetails} request={fetchLeadData}/>
      </div>,
    },
    {
      key: 'tasks',
      label: <Space>

        <GoTasklist fontSize={0} size={20}/>
        <F id={"pages.lead.drawer.tasks"} defaultMessage={'Tasks'}/>
        <Badge showZero={false} count={lead?.tasks_count}/>
      </Space>,
      children: <div>
        <TasksList leadId={leadId} filterColumns={column => column.dataIndex !== 'lead'} data={leadDetails?.tasks || []} request={fetchLeadData}/>
      </div>,
    },
    {
      key: 'comments',
      label: <Space>
        <LiaCommentsSolid fontSize={0} size={20}/>
        <F id={"pages.lead.drawer.comments"} defaultMessage={'Comments'}/>
        {commentsCount > 0 && <Badge color={"volcano"} showZero={false} count={commentsCount}/>}
      </Space>,
      children: <div>
        <LeadComments leadId={leadId} leadDetails={leadDetails} request={fetchLeadData}/>
      </div>,
    },
    {
      key: 'proposals',
      label: (
        <Tooltip title="Not activated">
          <div>Proposals</div>
        </Tooltip>
      ),
      children: <div>Proposals content</div>,
      disabled: true,
    },
    {
      key: 'documents',
      label: (
        <Tooltip title="Not activated">
          <div>Documents</div>
        </Tooltip>
      ),
      children: <div>Documents content</div>,
      disabled: true,
    },
  ];

  const dispatch = useDispatch();

  function toggle() {
    dispatch({
      type: 'leads/toggleLeadDetailsDrawer',
    });
  }

  useEffect(() => {
    if (isEmpty(detailsDrawer))
      return

    form.resetFields()
    fetchLeadData()
  }, [detailsDrawer]);


  const getTitle = () => <div>
    <Space>
      <F id={'pages.lead.drawer.details'}/>
      {lead && <LeadName readonly hideAvatar lead={lead}/>}
    </Space>
  </div>


  return (
    <div className={styles.drawerContainer}>
      <Drawer
        width={800}
        title={getTitle()}
        open={!isEmpty(detailsDrawer) && !isEmpty(lead)}
        destroyOnClose
        className={styles.drawer}
        onVisibleChange={toggle}
        drawerHeaderClassName={styles.drawerHeader}
      >
        {!isEmpty(lead) && <>
          <LeadDetailsHeader lead={lead}/>
          <div className={styles.leadDetailsSource}>
            <Space>
              <F id={'pages.leads.filters.leadSource.placeholder'} defaultMessage={'Lead Source'}/>
              <strong><LeadSource lead={lead}/></strong>
            </Space>
            <Space>
              <F id={'pages.lead.fields.updatedAt'} defaultMessage={'Last update'}/>
              <strong><LeadUpdatedAt isTable lead={lead}/></strong>
            </Space>
          </div>
          <Tabs className={classNames('ant-tab-type-button', styles.leadDetailsDrawerTabs)} defaultActiveKey="1" items={tabs}/>
        </>}
      </Drawer>
    </div>
  );
};

export default React.memo(LeadDetailsDrawer, () => true);

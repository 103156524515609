import React from 'react';
import {Divider, Empty, Space, Tag, Timeline} from 'antd';
import {useIntl} from "umi";
import get from "lodash/get";
import dayjs from "dayjs";

import LeadName from "@/components/Lead/LeadName";
import {PrettyDateWithTime} from "@/components/PrettyDate";
import {LeadStateTagPresentation} from "@/components/Filters/LeadStateFilters";
import {LeadActivityEvents} from "@/typings/models/LeadActivity";
import {LeadDetailsModel} from "@/typings/models/Lead";

import styles from "./index.less"
import {F} from "@/utils/intl";

const LeadActivity = ({leadDetails}: { leadDetails: LeadDetailsModel }) => {
  const activities = get(leadDetails, 'activities', []).filter(activity => activity?.activity_type !== 'comment')
  const intl = useIntl()
  const EVENT_TYPE_MAP = {
    [LeadActivityEvents.lead_state_changed]: intl.formatMessage({
      id: 'pages.lead.activities.changedStatus'
    }),
    [LeadActivityEvents.assignee_changed]: intl.formatMessage({
      id: 'pages.lead.activities.assigned'
    }),
    [LeadActivityEvents.created]: intl.formatMessage({
      id: 'pages.lead.activities.created'
    }),
    [LeadActivityEvents.branch_changed]: intl.formatMessage({
      id: 'pages.lead.activities.changedBranch'
    }),
    [LeadActivityEvents.customer_data_changed]: intl.formatMessage({
      id: 'pages.lead.activities.customerChanged'
    }),
    [LeadActivityEvents.archived]: intl.formatMessage({
      id: 'pages.lead.activities.archived'
    }),
    [LeadActivityEvents.unarchived]: intl.formatMessage({
      id: 'pages.lead.activities.unarchived'
    }),
    [LeadActivityEvents.appointment_rescheduled]: intl.formatMessage({
      id: 'pages.lead.activities.rescheduledAppointment'
    }),
  }

  const getValueComponent = (event, key) => {

    switch (event.activity_type) {

      case LeadActivityEvents.lead_state_changed:
        return <LeadStateTagPresentation state={event[key]}/>
      case LeadActivityEvents.assignee_changed:
        return <LeadName lead={{customer: event[key]}} readonly/>
      case LeadActivityEvents.branch_changed:
        return <Tag>{event[key]?.name}</Tag>
      case LeadActivityEvents.appointment_rescheduled:
        return <Space direction={"vertical"}>
          <PrettyDateWithTime date={event[key]?.utc_start_time}/>
          <PrettyDateWithTime date={event[key]?.utc_end_time}/>
        </Space>
    }

    return get(event[key], 'label', get(event[key], 'name', get(event[key], 'title', event[key])))
  }


  if (activities.length === 0) return <Empty/>

  const groupedActivities = activities.reverse().reduce((groups, activity) => {
    let activityDate = dayjs(activity.created).format('YYYY-MM-DD');
    const today = dayjs().format('YYYY-MM-DD');
    const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');

    if (activityDate === today)
      activityDate = intl.formatMessage({id: 'pages.common.today', defaultMessage: 'Today'})
    else if (activityDate === yesterday)
      activityDate = intl.formatMessage({id: 'pages.common.yesterday', defaultMessage: 'Yesterday'});
    else
      activityDate = dayjs(activity.created).format('dddd, DD MMMM YYYY');

    if (!groups[activityDate])
      groups[activityDate] = [];

    activity.created_time = dayjs(activity.created).format('HH:mm');
    groups[activityDate].push(activity);
    return groups;
  }, {});

  return <div className={styles.leadActivityContainer}>
    {Object.keys(groupedActivities).map((date, index) => (
      <div key={index}>
        <Divider><Tag className={styles.activityDate}>{date}</Tag></Divider>
        <Timeline>
          {groupedActivities[date].map((event, index) => (
            <Timeline.Item
              key={index}
            >
              <div className={styles.activityItemContainer}>
                <div className={styles.activityData}><Space direction={"vertical"}>
                  <Space>
                    <LeadName lead={{customer: event.editor}} readonly nameClassName={styles.activityEditorName}/>
                  </Space>
                  <Space style={{marginBottom: 5}} wrap>
                    <span>{EVENT_TYPE_MAP[event.activity_type]}</span>
                    {event.prev_value && (
                      <Space>
                        <F id={'pages.common.from'} defaultMessage={'from'}/>
                        {getValueComponent(event, 'prev_value')}
                      </Space>
                    )}
                    {event.new_value &&
                      <Space>
                        <F id={'pages.common.to'} defaultMessage={'to'}/>
                        {getValueComponent(event, 'new_value')}
                      </Space>}
                  </Space>
                </Space></div>
                <div className={styles.activityTime}>{event.created_time}</div>
              </div>

            </Timeline.Item>
          ))}
        </Timeline>
      </div>
    ))}
  </div>
};

export default LeadActivity;

import {useSortable} from "@dnd-kit/sortable";
import {Row, Spin, Tag} from "antd";
import classNames from "classnames";
import {useSelector} from "umi";
import React, {useEffect, useState} from "react";

import {getKanbanCardStyles} from "@/components/Kanban/utils";
import LeadContextMenu from "@/components/Lead/LeadContextMenu";
import * as PreferencesSelectors from "@/selectors/preferencesSelectors"
import * as LeadSelectors from "@/selectors/leadSelectors"

import styles from "./styles.less"
import {LeadCardOrderedProperties} from "@/components/Lead/LeadProperties";

function mergeRefs(refs) {
  return (node) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(node);
      } else if (ref && typeof ref === "object") {
        ref.current = node;
      }
    });
  };
}

type LeadCardProps = {
  disabled?: boolean,
  id: string,
  dragOverlay?: any,
  hideAssignee?: boolean,
  hideActions?: boolean
  disableDrag?: boolean
  archive?: boolean,
  overrideVisibleProperties?: string[]
  leadContextMenuProps?: any,
  recalculateCardHeight?: (height?: number) => void
}
const LeadCard = (props: LeadCardProps) => {
  const {id, dragOverlay, archive, overrideVisibleProperties, leadContextMenuProps = {}, recalculateCardHeight} = props;

  const lead = useSelector((state: DefaultRootState) => LeadSelectors.selectLeadData(state, id))
  const [draggable, setDraggable] = useState(true)

  const cardRef = React.useRef(null);

  const loading = false
  const {isHeatMap} =
    useSelector((state: DefaultRootState) => ({
      isHeatMap: PreferencesSelectors.selectIsHeatMap(state),
    }));

  useEffect(() => {
    if (cardRef?.current && recalculateCardHeight) recalculateCardHeight(cardRef.current.clientHeight)
  }, [cardRef?.current]);

  const disableDrag = !draggable || props.disableDrag || props.disabled
  const {
    setNodeRef,
    // setActivatorNodeRef,
    listeners,
    isDragging,
    // isSorting,
    // over,
    // overIndex,
    transform,
    transition,
    attributes
  } = useSortable({
    id: id,
    disabled: disableDrag,
    data: {
      type: "FIELD"
    }
  });

  const getBackgroundColor = () => {
    const currentDate = new Date();
    const modifiedDate = new Date(lead?.modified);
    const dayDiff = Math.floor((currentDate - modifiedDate) / (1000 * 60 * 60 * 24));
    let color;
    if (dayDiff <= 1) {
      color = '#e8f5de';
    } else if (dayDiff <= 3) {
      color = '#f7f4d4';
    } else if (dayDiff <= 7) {
      color = '#f7e2d4';
    } else if (dayDiff <= 14) {
      color = '#f7d4d4';
    } else {
      color = '#f7cccc';
    }
    return color;
  }

  const handleVisiblePropertiesChange = () => {
    if (recalculateCardHeight) recalculateCardHeight(cardRef.current?.clientHeight);
  }
  if (!lead?.customer) return null
  return (
    <div
      ref={mergeRefs([disableDrag ? null : setNodeRef, cardRef])}
      className={classNames(styles.kanbanCard, {
        [styles.draggable]: !disableDrag,
      })}
      style={{
        ...getKanbanCardStyles({
          transform,
          transition,
          dragOverlay,
          isDragging,
        }),
        borderTopColor: lead?.lead_state?.color,
        backgroundColor: isHeatMap ? getBackgroundColor() : null,
      }}
      {...attributes}
      {...listeners}
    >
      <Spin spinning={loading}>
        <LeadCardOrderedProperties onVisiblePropertiesChange={handleVisiblePropertiesChange} overrideVisibleProperties={overrideVisibleProperties} lead={lead}/>
        {!props.hideActions && (
          <LeadContextMenu
            actionsFilter={['details']}
            leadId={lead.id}
            className={styles.leadCardAction}
            setDraggable={setDraggable}
            {...(leadContextMenuProps || {})}
          />
        )}
        {isHeatMap && (
          <Row>
            <Tag color="blue">
              Modfied about{' '}
              {Math.floor((new Date() - new Date(lead.modified)) / (1000 * 60 * 60 * 24))} days ago
            </Tag>
          </Row>
        )}
      </Spin>
    </div>
  );
};


export default React.memo(LeadCard, (prevProps, nextProps) => prevProps.id === nextProps.id)
